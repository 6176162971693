import React from "react";

import discoverTitle from "../../../img/discoverTitle.png";
import DiscoverCard from "./DiscoverCard";

import DiscoverBg1 from "../../../img/1.png";
import DiscoverBg2 from "../../../img/2.png";
import DiscoverBg3 from "../../../img/3.png";
import DiscoverBg4 from "../../../img/4.png";

import { Discover1, Discover2, Discover3, Discover4 } from "../../../img/svgs";

const Discover = () => {
  return (
    <section className="mt-40">
      <div className="container flex flex-col items-center justify-center gap-12 px-4 md:px-8 lg:px-16">
        <div className="flex flex-col items-center justify-center gap-2">
          <img src={discoverTitle} alt="Discover Title" className="w-[150px]" />
          <span className="text-center text-[28px] font-semibold leading-[32px] text-[#100c08] md:text-[35px] md:leading-[40px] lg:text-[45px] lg:leading-[49.50px]">
            Exploring Tour Activities
          </span>
        </div>

        <div className="grid grid-cols-1 items-center justify-center gap-6 sm:grid-cols-2 lg:grid-cols-5">
          <div className="short-wrapper overflow-hidden rounded-lg">
            <iframe
              width="250"
              height="444"
              src="https://www.youtube.com/embed/1zROaK5_bHo"
              title="#uzbekistan Wanna travel Uzbekistan? Contact and get the best tour packages WhatsApp:+998881118532"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
          <div className="short-wrapper overflow-hidden rounded-lg">
            <iframe
              width="250"
              height="444"
              src="https://www.youtube.com/embed/yVOcl_qVt2Y"
              title="Travel and explore Uzbekistan with us! contact with us WHATSAPP:+998881118533 #travel #uzbekistan"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
          <div className="short-wrapper overflow-hidden rounded-lg">
            <iframe
              width="250"
              height="444"
              src="https://www.youtube.com/embed/xufrPR0h-fU"
              title="24 октября 2024 г."
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
          <div className="short-wrapper overflow-hidden rounded-lg">
            <iframe
              width="250"
              height="444"
              src="https://www.youtube.com/embed/n9FO4yZKWms"
              title="24 октября 2024 г."
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
          <div className="short-wrapper overflow-hidden rounded-lg">
            <iframe
              width="250"
              height="444"
              src="https://www.youtube.com/embed/T68G3xCkS1I"
              title="Karakalpakstan, Tuprokkala"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </div>

        <div className="hidden">
          <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
            <DiscoverCard
              title={"Bungee Jumping"}
              bg={DiscoverBg1}
              icon={<Discover1 />}
            />

            <DiscoverCard
              title={"Rafting"}
              bg={DiscoverBg2}
              icon={<Discover2 />}
            />

            <DiscoverCard
              title={"Paragliding"}
              bg={DiscoverBg3}
              icon={<Discover3 />}
            />

            <DiscoverCard
              title={"Ski touring"}
              bg={DiscoverBg4}
              icon={<Discover4 />}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Discover;
