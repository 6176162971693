import React, { useEffect } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

import toursTop from "../../img/toursTop.png";
import TourCard from "../../components/cards/TourCard";

const Tours = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex min-h-screen w-full flex-col justify-between">
      <Header />
      <main className="mt-20 flex flex-col gap-20">
        <div
          style={{ backgroundImage: `url(${toursTop})` }}
          className="flex h-[350px] w-full bg-cover bg-center bg-no-repeat"
        ></div>
        <div className="container flex flex-wrap items-center justify-between gap-5">
          <TourCard />
          <TourCard />
          <TourCard />
          <TourCard />
          <TourCard />
          <TourCard />
          <TourCard />
          <TourCard />
          <TourCard />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Tours;
