import React from "react";

import toursTitle from "../../../img/toursTitle.png";
import TourCard from "../../cards/TourCard";

const Tours = () => {
  return (
    <section className="mt-28">
      <div className="container flex flex-col items-center justify-center gap-12 px-4 md:px-8 lg:px-16">
        <div className="flex flex-col items-center justify-center gap-2">
          <img src={toursTitle} alt="Tours Title" className="w-[150px]" />
          <span className="text-center text-[28px] font-semibold leading-[32px] text-[#100c08] md:text-[35px] md:leading-[40px] lg:text-[45px] lg:leading-[49.50px]">
            Ultimate Travel Experience
          </span>
        </div>
        <div className="grid w-full grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          <TourCard />
          <TourCard />
          <TourCard />
        </div>
      </div>
    </section>
  );
};

export default Tours;
