import React from "react";
import testimonialsTitle from "../../../img/testimonialsTitle.png";
import testimonialImg from "../../../img/kk.JPG";

const Testimonials = () => {
  return (
    <section className="mt-28">
      <div className="container flex flex-col items-center justify-center gap-12 px-4 py-[90px] md:px-8 lg:px-16">
        <div className="flex flex-col items-center justify-center gap-2">
          <img
            src={testimonialsTitle}
            alt="Testimonials Title"
            className="w-[150px]"
          />
          <span className="text-center text-[28px] font-semibold leading-[32px] text-[#100c08] md:text-[35px] md:leading-[40px] lg:text-[45px] lg:leading-[49.50px]">
            What They Are Say!
          </span>
        </div>
        <div className="flex flex-col items-center justify-between gap-16 md:flex-row">
          <div className="w-full max-w-xs shrink-0 md:max-w-md lg:max-w-lg">
            <img
              src={testimonialImg}
              alt="Testimonials"
              className="h-[600px] w-full rounded-lg object-cover"
            />
          </div>
          <div className="flex flex-col items-start justify-center">
            <span className="text-[22px] font-semibold leading-[26px] tracking-wide text-[#100c08] md:text-[28px] md:leading-[32px] lg:text-[32px] lg:leading-[38.40px]">
              Discover the Enchantment of Uzbekistan
            </span>
            <p className="mt-8 text-[18px] font-normal leading-[24px] text-[#787878] md:text-[20px] md:leading-[28px] lg:text-[22px] lg:leading-[32px]">
              "Embarking on this journey with Dilnura Ergasheva has been a
              transformative experience. Her deep knowledge of Uzbekistan’s rich
              heritage and culture made every moment memorable. From stunning
              historical sites to vibrant local markets, her guidance ensured we
              uncovered the true essence of this beautiful country."
            </p>
            <span className="mt-14 text-[18px] font-semibold leading-snug text-[#100c08] md:text-[20px] lg:text-[22px]">
              Sophia Anderson
            </span>
            <span className="mt-2 text-lg font-normal leading-[18px] text-[#63ab45]">
              Travel Enthusiast & Blogger
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
