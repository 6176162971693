import tour1 from "../img/tour1.jpg";

export const tours = [
  {
    id: "1",
    title: "Uzbekistan City Tour",
    images: tour1, // Add URLs for images here
    status: "Available",
    duration: "6 days / 5 nights",
    included: [
      "Airport/Hotel pick-up-drop offs",
      "Transport access(car, bus, van)",
      "Professional guidance service",
      "4-5 Star Hotel Accommodation",
      "Trains normal/high speed",
      "Entrance Fee for Touristic Sites",
      "Complimentary City Maps",
      "Multilingual Support Guides",
      "Free Wi-Fi Access in Hotels",
      "Bottled Water During Tours",
    ],
    notIncluded: [
      "Charges in Hotels for Additional Services",
      "Personal Expenses",
      "Meals (Lunch/Dinner)",
      "Tourist Visa Fee",
      "Travel Insurance",
      "International Fly Tickets to/from Uzbekistan",
      "Photo Session",
      "Tips for Guides and Drivers",
      "Recreational Activities",
    ],
    originalPrice: "385",
    discount: "50",
    finalPrice: "335", // Reflecting the price after the discount
    description: `<p>
  Experience a captivating journey through Uzbekistan, visiting its most iconic cities and landmarks.
  Start in 
  <a href="https://en.wikipedia.org/wiki/Tashkent" target="_blank" class="text-blue-500 hover:underline">
    Tashkent
  </a>, the vibrant capital known for its stunning blend of modern and Soviet-era architecture.
  Travel to the ancient city of 
  <a href="https://en.wikipedia.org/wiki/Samarkand" target="_blank" class="text-blue-500 hover:underline">
    Samarkand
  </a>, a UNESCO World Heritage Site famous for the majestic Registan Square.
  Explore 
  <a href="https://en.wikipedia.org/wiki/Bukhara" target="_blank" class="text-blue-500 hover:underline">
    Bukhara
  </a>, a historical center of culture and religion with over 140 architectural monuments.
  This tour offers a perfect mix of cultural exploration and relaxation, ensuring a memorable experience in Uzbekistan.
</p>
`,
    advantages: [
      "Small group size for personalized experiences",
      "All-inclusive package (meals, transport, accommodations)",
      "Local expert guides with in-depth knowledge",
      "Flexible itinerary with free time to explore",
      "Great value for money with premium services",
    ],
    reviews: 4.8, // Average customer reviews (out of 5)
    availableDates: ["2024-05-10", "2024-06-15", "2024-07-20"], // Available dates for the tour
    languagesOffered: ["English", "Russian", "Spanish"], // Languages the tour is offered in
    maxGroupSize: "1-12", // Maximum number of participants
    cancellationPolicy: "Free cancellation up to 7 days before the tour", // Cancellation policy
    tourPlan: [
      {
        day: 1,
        title: "Arrival in Tashkent",
        activities: [
          "Arrive in Tashkent and check into your hotel.",
          "Visit Chorsu Bazaar for local shopping.",
          "Explore Kukaldosh Madrasah.",
          "Visit Khast Imam Complex to see the original copy of the Quran.",
          "Enjoy Tashkent Plov at Bashkozon and witness the cooking process.",
          "Stroll through Memorial Square.",
          "Experience the vibrant night scene in Tashkent.",
        ],
      },
      {
        day: 2,
        title: "Tashkent to Samarkand",
        activities: [
          "Depart for Samarkand by train in the early morning.",
          "Check into your hotel upon arrival.",
          "Discover Gur-e-Amir Mausoleum and Registan Square.",
          "Enjoy dinner with traditional dancing at a local restaurant.",
        ],
      },
      {
        day: 3,
        title: "Exploring Samarkand",
        activities: [
          "Visit Siyab Bazaar and experience local life.",
          "Tour Bibi-Khanym Mosque.",
          "Visit Konigil Paper Factory to see natural paper-making.",
          "Explore pottery workshops and the Ulugh Beg Observatory.",
        ],
      },
      {
        day: 4,
        title: "Samarkand to Bukhara",
        activities: [
          "Travel to Bukhara by train or van.",
          "Check into your hotel upon arrival.",
          "Explore Ark Fortress and visit Chor Minor.",
          "Enjoy lunch at a local restaurant and stroll through Bukhara.",
        ],
      },
      {
        day: 5,
        title: "Full Day in Bukhara",
        activities: [
          "Visit Sitorai Mohi Xosa, the summer palace.",
          "Explore Zindon prison.",
          "Visit Labi-Hovuz complex for relaxation.",
          "Experience the magical atmosphere of Bukhara at night.",
        ],
      },
      {
        day: 6,
        title: "Departure from Bukhara to Tashkent",
        activities: [
          "Check out from your hotel and enjoy breakfast.",
          "Travel back to Tashkent for your departure.",
        ],
      },
    ],
  },
];
