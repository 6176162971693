import React from "react";

const Upcoming = () => {
  return (
    <div className="flex w-full flex-col items-start gap-8">
      <span className="text-2xl font-bold leading-[18px] tracking-wide text-[#100c08]">
        Upcoming Tours
      </span>
      <div className="flex w-full flex-col gap-3">
        <div className="flex w-full items-center justify-between rounded-[5px] border border-[#100c0866] py-1.5 pl-[21px] pr-[20.63px]">
          <span className="">October 1</span>
          <span className="text-sm font-normal leading-[13px] text-[#222222]/50">
            Road To Bukhara Treasures
          </span>
        </div>

        <div className="flex w-full items-center justify-between rounded-[5px] border border-[#100c0866] py-1.5 pl-[21px] pr-[20.63px]">
          <span className="">October 1</span>
          <span className="text-sm font-normal leading-[13px] text-[#222222]/50">
            Road To Bukhara Treasures
          </span>
        </div>

        <div className="flex w-full items-center justify-between rounded-[5px] border border-[#100c0866] py-1.5 pl-[21px] pr-[20.63px]">
          <span className="">October 1</span>
          <span className="text-sm font-normal leading-[13px] text-[#222222]/50">
            Road To Bukhara Treasures
          </span>
        </div>
      </div>
    </div>
  );
};

export default Upcoming;
