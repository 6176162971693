import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Upcoming from "../../components/upcoming/Upcoming";
import { tours } from "../../utils/toursDb";
import {
  FaCalendarAlt,
  FaCheck,
  FaTimes,
  FaMapMarkerAlt,
  FaClock,
} from "react-icons/fa";

const Tour = () => {
  const { tourId } = useParams();
  const [currentTour, setCurrentTour] = useState(null);
  const [activeTab, setActiveTab] = useState("overview");

  useEffect(() => {
    const tour = tours.find((tour) => tour.id === tourId);
    setCurrentTour(tour);
  }, [tourId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!currentTour) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <div className="h-32 w-32 animate-spin rounded-full border-b-2 border-gray-900"></div>
      </div>
    );
  }

  const TabContent = ({ id, active, children }) => (
    <div
      className={`mt-4 rounded-lg border p-4 ${active ? "block" : "hidden"}`}
    >
      {children}
    </div>
  );

  return (
    <div className="flex min-h-screen w-full flex-col justify-between">
      <Header />
      <main className="container mx-auto mt-28 px-4">
        <div className="grid gap-10 lg:grid-cols-3">
          <div className="lg:col-span-2">
            <h1 className="mb-6 text-4xl font-bold">{currentTour.title}</h1>
            <div className="mb-6 overflow-hidden rounded-lg">
              <img
                src={currentTour.images}
                alt={currentTour.title}
                className="h-[400px] w-full object-cover transition-transform duration-300 hover:scale-105"
              />
            </div>

            <div className="mb-4">
              <div className="flex border-b">
                {["overview", "itinerary", "included"].map((tab) => (
                  <button
                    key={tab}
                    className={`px-4 py-2 ${activeTab === tab ? "border-b-2 border-blue-500 font-semibold" : ""}`}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab.charAt(0).toUpperCase() + tab.slice(1)}
                  </button>
                ))}
              </div>
            </div>

            <TabContent id="overview" active={activeTab === "overview"}>
              <p
                className="text-gray-700"
                dangerouslySetInnerHTML={{ __html: currentTour.description }}
              ></p>
              <div className="mt-4 flex items-center gap-4">
                <div className="flex items-center gap-2">
                  <FaClock className="text-gray-500" />
                  <span>{currentTour.duration}</span>
                </div>
                <div className="flex items-center gap-2">
                  <FaMapMarkerAlt className="text-gray-500" />
                  <span>{currentTour.location}</span>
                </div>
              </div>
            </TabContent>

            <TabContent id="itinerary" active={activeTab === "itinerary"}>
              {currentTour.tourPlan.map((day, index) => (
                <div key={index} className="mb-6">
                  <h3 className="text-xl font-semibold">{`Day ${day.day}: ${day.title}`}</h3>
                  <ul className="list-disc pl-5">
                    {day.activities.map((activity, idx) => (
                      <li key={idx} className="text-gray-700">
                        {activity}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </TabContent>

            <TabContent id="included" active={activeTab === "included"}>
              <div className="mb-4">
                <h3 className="mb-2 font-semibold">Included:</h3>
                <ul className="space-y-2">
                  {currentTour.included.map((item, index) => (
                    <li key={index} className="flex items-center gap-2">
                      <FaCheck className="text-green-500" />
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h3 className="mb-2 font-semibold">Not Included:</h3>
                <ul className="space-y-2">
                  {currentTour.notIncluded.map((item, index) => (
                    <li key={index} className="flex items-center gap-2">
                      <FaTimes className="text-red-500" />
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </TabContent>
          </div>

          <div className="lg:col-span-1">
            <div className="rounded-lg border p-4 shadow-sm">
              <div className="mb-4 flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <span className="text-3xl font-bold text-green-500">
                    ${currentTour.finalPrice}
                  </span>
                </div>
                <span className="text-lg text-gray-500 line-through">
                  ${currentTour.originalPrice}
                </span>
              </div>
              <div className="mb-4 flex items-center gap-2">
                <FaCalendarAlt className="text-gray-500" />
                <span>{currentTour.availableDates[0]}</span>
              </div>
              <button className="w-full rounded bg-blue-500 px-4 py-2 font-semibold text-white transition-colors hover:bg-blue-600">
                Book Now
              </button>
            </div>
            <div className="mt-6">
              <Upcoming />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Tour;
