import React from "react";
import Header from "../../components/header/Header";
import Intro from "../../components/home/intro/Intro";
import Discover from "../../components/home/discover/Discover";
import About from "../../components/home/about/About";
import Explore from "../../components/home/explore/Explore";
import Tours from "../../components/home/tours/Tours";
import Testimonials from "../../components/home/testimonials/Testimonials";
import Footer from "../../components/footer/Footer";

const Home = () => {
  return (
    <div className="bg-white">
      <Header />
      <Intro />
      <Discover />
      <About />
      <Explore />
      <Tours />
      <Testimonials />
      <Footer />
    </div>
  );
};

export default Home;
