import React from "react";

const ClientsCard = ({ image, title, description }) => {
  return (
    <div className="flex w-full flex-col border-b-2 border-b-gray-300 pb-8">
      <img
        className="relative h-[428px] w-[856px] rounded-[5px] object-cover"
        src={image}
        alt={"Card"}
      />
      <div className="mt-3 flex hidden w-full items-center justify-end gap-1">
        <span className="text-sm text-gray-400">September 17, 2024</span>
      </div>
      <span className="mt-10 text-[35px] font-semibold capitalize leading-[45.50px] text-[#100c08]">
        {title}
      </span>
      <span className="mt-6 text-base font-normal leading-[30.40px] tracking-tight text-[#787878]">
        {description}
      </span>
    </div>
  );
};

export default ClientsCard;
