import React from "react";

import exploreBg from "../../../img/exploreBg.png";
import exploreTitle from "../../../img/exploreTitle.png";

import explore1 from "../../../img/explore1.jpg";
import explore2 from "../../../img/explore2.webp";
import explore3 from "../../../img/explore3.jpg";
import explore4 from "../../../img/explore4.png";
import { Link } from "react-router-dom";

const Explore = () => {
  return (
    <section className="mt-28">
      <div
        style={{ backgroundImage: `url(${exploreBg})` }}
        className="container flex flex-col items-center justify-center px-4 py-[90px] md:px-8 lg:px-16"
      >
        <div className="flex flex-col items-center justify-center gap-2">
          <img
            src={exploreTitle}
            alt="Explore Background"
            className="w-[150px]"
          />
          <span className="text-center text-[28px] font-semibold leading-[32px] text-[#100c08] md:text-[35px] md:leading-[40px] lg:text-[45px] lg:leading-[49.50px]">
            Finest Tourist Spot
          </span>
        </div>
        <div className="mt-12 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
          <div className="flex flex-col overflow-hidden rounded-[10px]">
            <img
              className="h-[200px] w-full object-cover sm:h-[250px] lg:h-[285px]"
              src={explore1}
              alt="Explore"
            />
            <span className="py-4 text-center text-xl font-medium capitalize leading-normal text-[#100c08] md:text-2xl">
              Samarkand
            </span>
          </div>

          <div className="flex flex-col overflow-hidden rounded-[10px]">
            <img
              className="h-[200px] w-full object-cover sm:h-[250px] lg:h-[285px]"
              src={explore2}
              alt="Explore"
            />
            <span className="py-4 text-center text-xl font-medium capitalize leading-normal text-[#100c08] md:text-2xl">
              Bukhara
            </span>
          </div>

          <div className="flex flex-col overflow-hidden rounded-[10px]">
            <img
              className="h-[200px] w-full object-cover sm:h-[250px] lg:h-[285px]"
              src={explore3}
              alt="Explore"
            />
            <span className="py-4 text-center text-xl font-medium capitalize leading-normal text-[#100c08] md:text-2xl">
              Khiva
            </span>
          </div>

          <div className="flex flex-col overflow-hidden rounded-[10px]">
            <img
              className="h-[200px] w-full object-cover sm:h-[250px] lg:h-[285px]"
              src={explore4}
              alt="Explore"
            />
            <span className="py-4 text-center text-xl font-medium capitalize leading-normal text-[#100c08] md:text-2xl">
              Tashkent
            </span>
          </div>
        </div>

        <div className="mt-12 flex items-center justify-center">
          <button className="flex items-center justify-center bg-[#fbb03b]">
            <Link
              to={"/tours"}
              className="px-[20px] py-3 text-sm font-semibold capitalize leading-none text-[#100c08] md:px-[30px] md:py-4 md:text-base"
            >
              View All Destination
            </Link>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Explore;
