import React from "react";

const DiscoverCard = ({ bg, icon, title }) => {
  return (
    <div
      style={{ backgroundImage: `url(${bg})` }}
      className="flex h-[375px] w-full max-w-[305px] items-end justify-center overflow-hidden rounded-[5px] bg-cover bg-center bg-no-repeat p-4"
    >
      <div className="flex w-full items-center justify-start gap-[15px] rounded-[5px] bg-white py-5 pl-[25px] pr-[30.85px]">
        <div className="flex w-14 items-center justify-start border-r border-[#100c08]/10 pr-4">
          {icon}
        </div>
        <div className="text-lg font-medium capitalize leading-[23px] text-[#100c08]">
          {title}
        </div>
      </div>
    </div>
  );
};

export default DiscoverCard;
