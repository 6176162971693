import React from "react";

import aboutTitle from "../../../img/aboutTitle.png";
import aboutImg from "../../../img/aboutBg.jpg";
import { About1, About2, About3, About4, HeaderPhone } from "../../../img/svgs";

const About = () => {
  return (
    <section className="mt-24">
      <div className="container flex flex-col items-center justify-between gap-6 md:flex-row">
        <div className="flex flex-1 items-center justify-center">
          <img
            className="w-full max-w-[550px] rounded-[10px]"
            src={aboutImg}
            alt="About"
          />
        </div>
        <div className="flex flex-1 flex-col items-start justify-between">
          <img src={aboutTitle} alt="About Title" className="w-[150px]" />
          <span className="mt-2 text-[28px] font-semibold leading-[32px] text-[#100c08] md:text-[35px] md:leading-[40px] lg:text-[45px] lg:leading-[49.50px]">
            Experience Uzbekistan with Us
          </span>
          <span className="mt-8 text-[17px] font-normal leading-loose tracking-wide text-[#787878] md:text-[20px] md:leading-[28px] lg:text-[22px] lg:leading-[32px]">
            We specialize in providing professional guided tours across the
            stunning landscapes and historic cities of Uzbekistan. Whether
            you're looking for a relaxing city tour or an adventurous mountain
            escape, we offer customized tour packages tailored to your
            preferences. Our packages include comfortable stays at 4-5 star
            hotels, experienced guides, and reliable transport services — all at
            reasonable prices. Let us help you explore the best of Uzbekistan
            with a personalized and unforgettable travel experience.
          </span>

          <div className="mt-11 flex flex-col gap-5 md:flex-row">
            <div className="flex flex-col gap-5">
              <div className="flex origin-top-left -rotate-3 items-center justify-start gap-[15px] rounded-[10px] bg-[#63ab451a] py-[1.79px] pl-[0.01px] pr-[74.75px]">
                <div className="flex flex-col items-center justify-center self-stretch rounded-bl-[5px] rounded-tl-[5px] bg-[#63ab454d] px-2.5 pb-[15.01px] pt-[15px]">
                  <About1 />
                </div>
                <div className="text-lg font-bold capitalize leading-snug tracking-tight text-[#100c08]">
                  Safety First Always
                </div>
              </div>

              <div className="flex origin-top-left -rotate-3 items-center justify-start gap-[15px] rounded-[10px] bg-[#63ab451a] py-[1.79px] pl-[0.01px] pr-[74.75px]">
                <div className="flex flex-col items-center justify-center self-stretch rounded-bl-[5px] rounded-tl-[5px] bg-[#63ab454d] px-2.5 pb-[15.01px] pt-[15px]">
                  <About2 />
                </div>
                <div className="text-lg font-bold capitalize leading-snug tracking-tight text-[#100c08]">
                  Time Stress Savings
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-5">
              <div className="flex origin-top-left -rotate-3 items-center justify-start gap-[15px] rounded-[10px] bg-[#63ab451a] py-[1.79px] pl-[0.01px] pr-[74.75px]">
                <div className="flex flex-col items-center justify-center self-stretch rounded-bl-[5px] rounded-tl-[5px] bg-[#63ab454d] px-2.5 pb-[15.01px] pt-[15px]">
                  <About3 />
                </div>
                <div className="text-lg font-bold capitalize leading-snug tracking-tight text-[#100c08]">
                  Trusted Travel Guide
                </div>
              </div>

              <div className="flex origin-top-left -rotate-3 items-center justify-start gap-[15px] rounded-[10px] bg-[#63ab451a] py-[1.79px] pl-[0.01px] pr-[74.75px]">
                <div className="flex flex-col items-center justify-center self-stretch rounded-bl-[5px] rounded-tl-[5px] bg-[#63ab454d] px-2.5 pb-[15.01px] pt-[15px]">
                  <About4 />
                </div>
                <div className="text-lg font-bold capitalize leading-snug tracking-tight text-[#100c08]">
                  500+ Tour Success
                </div>
              </div>
            </div>
          </div>

          <div className="mt-10 flex flex-col gap-10 md:flex-row">
            <button className="flex items-center justify-center bg-[#fbb03b] py-[17px] pl-[30px] pr-[29.63px]">
              <span className="text-base font-semibold capitalize leading-none text-[#100c08]">
                Discover More
              </span>
            </button>

            <div className="gap flex items-center">
              <div className="flex border-r-2 border-r-gray-300 px-2">
                <HeaderPhone />
              </div>
              <div className="flex flex-col items-start justify-center gap-1 px-2">
                <span className="text-sm font-medium leading-[14px] text-[#100c08]">
                  To More Inquiry
                </span>
                <span className="text-lg font-semibold leading-tight text-[#63ab45]">
                  <a href="tel:+998(88)111-85-32">+998(88)111-85-32</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
