import React from "react";
import {
  IntroFeaturesAdven,
  IntroFeaturesCalendar,
  IntroFeaturesLike,
  IntroFeaturesPlane,
} from "../../../img/svgs";

import bgIntro from "../../../img/introBg.jpg";
import { tours } from "../../../utils/toursDb";
import { NavLink } from "react-router-dom";

const Intro = () => {
  const topTour = tours[0];

  return (
    <section className="flex h-[790px] items-center justify-center">
      <div
        style={{ backgroundImage: `url(${bgIntro})` }}
        className="intro container flex h-[675px] items-center justify-end bg-cover bg-bottom bg-no-repeat"
      >
        <div className="relative flex w-full max-w-[445px] flex-col items-center rounded-[5px] border border-gray-500 bg-[#00000070] px-10 pb-[60px] pt-[100px] backdrop-blur-lg">
          <div className="absolute left-0 top-5 flex items-center justify-center bg-[#008000] py-1.5 pl-2 pr-[7.70px]">
            <span className="text-xs font-medium uppercase leading-3 tracking-wide text-white">
              Featured
            </span>
          </div>
          <div className="absolute left-0 top-12 flex items-center justify-center gap-[7px] border border-gray-600 bg-gray-400 py-[7px] pl-[11px] pr-[10.64px]">
            <IntroFeaturesAdven />
            <span className="text-[13px] font-normal capitalize leading-[13px] text-[#100c08]">
              Adventure Tours
            </span>
          </div>
          <span className="flex w-full items-center justify-start text-[28px] font-medium text-white">
            {topTour.title}
          </span>
          <div className="mb-3 mt-3 flex w-full items-center justify-start gap-1">
            <IntroFeaturesCalendar />
            <span className="text-[13px] font-medium capitalize leading-[13px] text-white">
              {topTour.duration}
            </span>
          </div>
          <div className="flex w-full items-start justify-between border-y-2 border-y-gray-400 py-3">
            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-1">
                <IntroFeaturesLike />
                <span className="text-[15px] font-normal capitalize leading-[15px] text-white">
                  {topTour.included[0]}
                </span>
              </div>
              <div className="flex items-center gap-1">
                <IntroFeaturesLike />
                <span className="text-[15px] font-normal capitalize leading-[15px] text-white">
                  {topTour.included[1]}
                </span>
              </div>
              <div className="flex items-center gap-1">
                <IntroFeaturesLike />
                <span className="text-[15px] font-normal capitalize leading-[15px] text-white">
                  {topTour.included[2]}
                </span>
              </div>
              <div className="flex items-center gap-1">
                <IntroFeaturesLike />
                <span className="text-[15px] font-normal capitalize leading-[15px] text-white">
                  {topTour.included[3]}
                </span>
              </div>
            </div>
          </div>

          <div className="flex w-full items-center justify-between pt-6">
            <div className="flex flex-col">
              <span className="text-xs font-normal capitalize leading-3 tracking-tight text-white">
                Starting From:
              </span>

              <div className="mt-0 flex items-center gap-1">
                <span className="text-[26px] font-medium capitalize leading-snug tracking-tight text-[#63ab45]">
                  ${topTour.finalPrice}
                </span>
                <span className="text-[15px] font-normal capitalize leading-snug tracking-tight text-white line-through">
                  ${topTour.originalPrice}
                </span>
              </div>
              <span className="mt-0 text-[10px] font-normal capitalize leading-snug tracking-tight text-white">
                {topTour.maxGroupSize} People Max
              </span>
            </div>

            <button className="flex items-center justify-center gap-[6.68px] bg-[#63ab45]">
              <NavLink
                to={"/tour/" + topTour.id}
                className="flex items-center justify-center gap-[6.68px] px-[17px] py-[11px] text-sm font-medium capitalize leading-[14px] text-white"
              >
                <span>See More</span>
                <IntroFeaturesPlane />
              </NavLink>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;
