import { Routes, Route } from "react-router-dom";
import Home from "./routes/home/Home";
import Tours from "./routes/tours/Tours";
import Clients from "./routes/clients/Clients";
import About from "./routes/about/About";
import Contact from "./routes/contact/Contact";
import Tour from "./routes/tour/Tour";
import Gallery from "./routes/gallery/Gallery";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tours" element={<Tours />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/about" element={<About />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/tour/:tourId" element={<Tour />} />
      </Routes>
      <Analytics />
      <SpeedInsights />
    </div>
  );
}

export default App;
