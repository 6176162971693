import React, { useState, useEffect } from "react";
import { HeaderLogo, HeaderPhone } from "../../img/svgs";
import { Link, NavLink } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Prevent scroll when mobile menu is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Close mobile menu when clicking on a link
  const handleNavClick = () => {
    setIsOpen(false);
  };

  return (
    <header className="fixed left-0 top-0 z-[1000] w-full bg-emerald-50 py-5 shadow-sm">
      <div className="container flex flex-wrap items-center justify-between">
        <Link to="/" className="flex-shrink-0">
          <HeaderLogo className="h-8 w-auto sm:h-10" />
        </Link>

        <div className="z-[1001] block lg:hidden">
          <button
            onClick={toggleMenu}
            className="rounded-lg p-2 text-gray-500 transition-colors hover:bg-emerald-100 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#63ab45]"
            aria-label={isOpen ? "Close menu" : "Open menu"}
          >
            {isOpen ? (
              <FaTimes className="h-6 w-6" />
            ) : (
              <FaBars className="h-6 w-6" />
            )}
          </button>
        </div>

        {/* Backdrop overlay */}
        {isOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 transition-opacity lg:hidden"
            onClick={toggleMenu}
          />
        )}

        <nav
          className={`fixed right-0 top-0 z-[1000] h-full w-72 transform overflow-y-auto bg-emerald-50 p-2 shadow-lg transition-transform duration-300 ease-in-out lg:static lg:flex lg:h-auto lg:w-auto lg:transform-none lg:items-center lg:justify-center lg:overflow-visible lg:shadow-none ${
            isOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <ul className="flex flex-col space-y-4 lg:flex-row lg:items-center lg:space-x-8 lg:space-y-0">
            <li
              className={`flex items-center justify-center ${isOpen ? "pt-20" : ""}`}
            >
              <NavLink
                to={"/"}
                onClick={handleNavClick}
                className={({ isActive }) =>
                  `block w-full border-b-2 py-2 text-center font-medium transition-colors lg:w-auto lg:py-1 ${
                    isActive
                      ? "border-b-[#63ab45] text-[#63ab45]"
                      : "border-b-transparent hover:border-b-[#63ab45] hover:text-[#63ab45]"
                  }`
                }
              >
                Home
              </NavLink>
            </li>
            {[
              { to: "/about", label: "About" },
              { to: "/tours", label: "Tours" },
              { to: "/gallery", label: "Gallery" },
              { to: "/contact", label: "Contact" },
              { to: "/clients", label: "Happy Clients :)" },
            ].map((item) => (
              <li key={item.to} className="flex items-center justify-center">
                <NavLink
                  to={item.to}
                  onClick={handleNavClick}
                  className={({ isActive }) =>
                    `block w-full border-b-2 py-2 text-center font-medium transition-colors lg:w-auto lg:py-1 ${
                      isActive
                        ? "border-b-[#63ab45] text-[#63ab45]"
                        : "border-b-transparent hover:border-b-[#63ab45] hover:text-[#63ab45]"
                    }`
                  }
                >
                  {item.label}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>

        <div className="hidden lg:flex lg:items-center lg:gap-4">
          <div className="flex border-r-2 border-r-gray-300 px-2">
            <HeaderPhone />
          </div>
          <div className="flex flex-col items-start justify-center gap-1 px-2">
            <span className="text-sm font-medium leading-[14px] text-[#100c08]">
              To More Inquiry
            </span>
            <span className="text-lg font-semibold leading-tight text-[#63ab45]">
              <a href="tel:+998(88)111-85-32">+998(88)111-85-32</a>
            </span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
