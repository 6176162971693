import React, { useEffect } from "react";

import gallery1 from "../../img/gallery/gallery (1).jpg";
import gallery2 from "../../img/gallery/gallery (2).jpg";
import gallery3 from "../../img/gallery/gallery (3).jpg";
import gallery4 from "../../img/gallery/gallery (4).jpg";
import gallery5 from "../../img/gallery/gallery (5).jpg";
import gallery6 from "../../img/gallery/gallery (6).jpg";
import gallery7 from "../../img/gallery/gallery (7).jpg";
import gallery8 from "../../img/gallery/gallery (8).jpg";
import gallery9 from "../../img/gallery/gallery (9).jpg";
import gallery10 from "../../img/gallery/gallery (10).jpg";
import gallery11 from "../../img/gallery/gallery (11).jpg";
import gallery12 from "../../img/gallery/gallery (12).jpg";
import gallery13 from "../../img/gallery/gallery (13).jpg";
import gallery14 from "../../img/gallery/gallery (14).jpg";
import gallery15 from "../../img/gallery/gallery (15).jpg";
import gallery16 from "../../img/gallery/gallery (16).jpg";
import gallery17 from "../../img/gallery/gallery (17).jpg";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

const images = [
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
  gallery8,
  gallery9,
  gallery10,
  gallery11,
  gallery12,
  gallery13,
  gallery14,
  gallery15,
  gallery16,
  gallery17,
];

// Function to shuffle the images array
const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const Gallery = () => {
  const shuffledImages = shuffleArray([...images]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />

      <div className="container mt-24 grid auto-rows-[1fr] grid-cols-1 gap-4 p-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {shuffledImages.map((src, index) => (
          <div
            key={index}
            className="group relative m-0 overflow-hidden rounded-lg"
          >
            <img
              src={src}
              alt={`Gallery ${index + 1}`}
              loading="lazy"
              className="h-auto w-full transform rounded-lg object-cover transition-transform duration-300 group-hover:scale-105"
            />
          </div>
        ))}
      </div>

      <Footer />
    </>
  );
};

export default Gallery;
